import React from "react";

import "./type.css";
import "./reference.css";
import {Item} from "./item.js";
import {Config} from "../../config/config.js";

const REFERENCE = "reference";

const SELECTED_ANY_CLASS = "selectedAnyItem";

export class Reference extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			books: props.books,
			referenceCallback: props.referenceCallback,
			book: "",
			chapter_num: "",
			verse_num: "",
			chapter_count: 0,
			verse_count: 0,
		}

		let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";
	}

	search = () => {
		//console.log("search()");

		if ( this.state.book.length > 0 && this.state.chapter_num > 0 &&
			this.state.verse_num > 0 ) {

			this.state.referenceCallback( REFERENCE, this.state.book, 
				this.state.chapter_num, this.state.verse_num);
		}

	}


	onChangeBook = (book_name) => {
		//console.log("onChangeBook()");

		this.setState( 
				{
					book: book_name,
					chapter_num: "",
					verse_num: "",
					chapter_count: 0,
					verse_count: 0,
				} 
			);

		for ( let i in this.state.books ) {
			let book = this.state.books[i];

			if ( book.book_name === book_name ) {
				//console.log("book_name: " + book_name );
				//console.log( "chapter_count: " + book.chapter_count);

				setTimeout( () => this.setState( 
					{ 
						chapter_count: book.chapter_count
					} 
				), 100 );

				break;
			}

		}

	}


	onChangeChapter = (chapter_num) => {
		//console.log("onChangeChapter()");

		this.setState( 
			{
				chapter_num: chapter_num,
				verse_num: "",
				verse_count: 0,
			} 
		);

		let url = this.urlPrefix + Config.getSearchUrl() + 
			'?request=verse_count&book_name=' + this.state.book +
			'&chapter_num=' + chapter_num;

		let options = {
			method: "GET",
		};

		let request = new Request(url, options);

		this.getVerseCount(request);
	}


	async getVerseCount(request) {

		try {

			let response = await fetch(request);

			if (response.ok) {
				let verse_count = await response.text()
				//console.log("verse_count: " + verse_count);
				this.setState( {verse_count: verse_count} );
			} else {
				let text = await response.text();
				console.error(text);
			}

		}

		catch(exc) {
			console.error(exc);
		}

	}


	onChangeVerse = (verse_num) => {
		//console.log("onVerseChange()");

		this.setState( {verse_num: verse_num} );
	}


	render() {

		let chapters = [];

		for ( let i = 1; i <= this.state.chapter_count; ++i ) {
			chapters.push(i);
		}

		let verses = [];

		for ( let i = 1; i <= this.state.verse_count; ++i ) {
			verses.push(i);
		}

		return(
			<div className="typeContainer">
				<div className="typeContainerHeading">
					Search By Scripture Reference
				</div>

				<div className="referenceRow">

					<div className="referenceBookBox">
						<div className="referenceLabel">Book</div>
						<div className="referenceSelectedValueBook">{this.state.book}</div>

						<div className="referenceSelectBox">

							{
								this.state.books.map( (book, key) =>
									<Item key={key} value={book.book_name}
									selectCallback={this.onChangeBook}
									class_name="selectItem" selected_class={SELECTED_ANY_CLASS}/>
								)

							}

						</div>

					</div>

					<div className="referenceChapterBox">
						<div className="referenceLabel">Chapter</div>
						<div className="referenceSelectedValue">
							{this.state.chapter_num}
						</div>

						<div className="referenceSelectBox">

							{
								chapters.map( (chapter_num, key) =>
									<Item key={key} value={chapter_num}
										selectCallback={this.onChangeChapter} 
									class_name="selectItem" selected_class={SELECTED_ANY_CLASS}/>)
							}

						</div>

					</div>

					<div className="referenceVerseBox">

						<div className="referenceLabel">Verse</div>
						<div className="referenceSelectedValue">{this.state.verse_num}</div>

						<div className="referenceSelectBox">

							{
								verses.map( (verse_num, key) =>
									<Item key={key} value={verse_num}
										selectCallback={this.onChangeVerse} 
									class_name="selectItem" selected_class={SELECTED_ANY_CLASS}/>)
							}

						</div>

					</div>

				</div>

				<div className="referenceSearchButtonContainer">
					<button className="referenceSearchButton" onClick={this.search}>
						Search</button>
				</div>

			</div>
		);

	}

}

