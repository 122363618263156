
const CONTACT_US_URL = "/cgi-bin/app/ContactUs.py";
const RETRIEVE_GALLERY_URL = "/cgi-bin/app/RetrieveGallery.py";
const ARTICLES_URL = "/cgi-bin/app/Articles.py";
const STARS_DIRECTORY = "/app/images/stars/";
const REVIEWS_URL = "/cgi-bin/app/Reviews.py";
const GRID_TABLE_URL = "/cgi-bin/app/GridTable.py";
const IMAGE_URL = "/cgi-bin/app/Images.py";
const LEXICON_URL = "/lexicon/lexicon.json";
const SEARCH_URL = "/cgi-bin/app/Search.py";
const LOG_HIT_URL = "/cgi-bin/app/LogHit.py";
const SONGBOOK_URL = "/songbook"
const SONGBOOK_CONFIG = "songbook_config.json";
const PRAYER_REQUEST_URL = "/cgi-bin/app/PrayerRequest.py";

const MAP_SPEC = {
		map_token: "pk.eyJ1IjoiZG1vbnRhZ25lc2UiLCJhIjoiY2toODJudHF1MTZoNjJxbzhqOGoyOW9mbSJ9.AYjVnmZS-oqABx5xoSy3CQ",
		//latitude: 33.679717, 
		//longitude:-112.131644,
		//address: "3325 W. Quail Ave Phoenix AZ 85027",
		//zoom: 15,
		//latitude: 36.124475, 
		//longitude:-112.1155,
		latitude: 36.057492, 
		longitude:-112.137628,
		address: "Grand Canyon National Park",
		zoom: 10,
	};

export class Config {

	static getContactUsUrl() {
		return CONTACT_US_URL;
	}

	static getRetrieveGalleryUrl() {
		return RETRIEVE_GALLERY_URL;
	}

	static getArticlesUrl() {
		return ARTICLES_URL;
	}

	static getStarsDirectory() {
		return STARS_DIRECTORY;
	}

	static getUrlPrefix() {
		let host = window.location.host.split(':')[0];
		return window.location.protocol + "//" + host + "/";
	}

	static getReviewsUrl() {
		return REVIEWS_URL;
	}

	static getGridTableUrl() {
		return GRID_TABLE_URL;
	}

	static getImageUrl() {
		return IMAGE_URL;
	}

	static getMapSpec() {
		return MAP_SPEC;
	}

	static getLexiconUrl() {
		return LEXICON_URL;
	}

	static getSearchUrl() {
		return SEARCH_URL;
	}

	static getLogHitUrl() {
		return LOG_HIT_URL;
	}

	static getSongBookUrl() {
		return SONGBOOK_URL;
	}

	static getSongBookConfig() {
		return SONGBOOK_CONFIG;
	}

	static getPrayerRequestUrl() {
		return PRAYER_REQUEST_URL;
	}

}
