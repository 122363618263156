import React from "react";
import {NavLink} from "react-router-dom";
import {SharedData} from "../../sharedData/sharedData.js";
import {Utils} from "../../utils/utils.js";
import "./tab.css";

export class Tab extends React.Component {

	constructor(props) {
		super(props);

		this.group = props.group;
		this.index = props.index;

		this.dropDownClasses = 
			`dropdown-menu tabDropDownMenu ${SharedData.getDropDownClass()}`;
	}

	/**
	componentDidMount() {
		//console.log( "Tab:componentDidMount()" );

			The home tab id is set so that the home page does not
			display the Home tab.

		if ( this._emptyLabel && 0 === this.index ) {
			//console.log( "Setting the home tag" );
			this._emptyLabel.setAttribute( "id", SharedData.getHomeTabId() );
		}

	}
	*/

	toggleDropDown = (event) => {
		//console.log("toggleDropDown()");

		if ( "block" === this._dropDown.style.display ) {
			Utils.hideAllDropDowns();
			//this._dropDown.style.display = "none";
		} else {
			this._dropDown.style.display = "block";
		}

	}

	slideMenuOut = (event) => {
		//console.log("Tab:slideMenuOut()");
		Utils.slideMenuOut();

		if ( null == this._dropDown ) {
			//console.log("sliding out");
			Utils.hideAllDropDowns();
		}

	}

	render() {
		let self = this;

		if ( this.group.category ) {

			return (
				<div className="dropdown tabContainer bgcomp fgwhite">

					<button type="button" 
						className="tabButton bgcomp fgwhite dropdown-toggle"
							data-toggle="dropdown"
							aria-expanded="true"
							onClick={this.toggleDropDown}>
							{this.group.category}
					</button>

					<div className={this.dropDownClasses}
						ref={ element => self._dropDown = element }>
						{
							this.group.routes.map( route =>
								<div key={route.path} 
									className="tabDropDownItem bgcomp fgwhite"
									data-toggle="dropdown" 
									onClick={this.toggleDropDown}>
									<NavLink to={route.path} 
										className="tabLink bgcomp fgwhite"
											onClick={this.slideMenuOut}>
										{route.label}
									</NavLink>
								</div>
							)
						}
					</div>

				</div>
			)

		} else {
			let activeClassName="tabLinkActive";

			/*
			if ( "Home" === this.group.routes[0].label ) {
				activeClassName = "";
			}
			*/

			return(
					<div className="tabContainer bgcomp fgwhite"
						ref={ 
								element => this._emptyLabel = element
							}>

						<NavLink className="tabLink bgcomp fgwhite" 
							activeClassName={activeClassName}
							to={this.group.routes[0].path}
							onClick={this.slideMenuOut}>
							{this.group.routes[0].label}
						</NavLink>

					</div>
			);

		}

	}

}
