import React from "react";
import "./article.css";
import "./articleDisplay.css";
import {Config} from "../../config/config.js";

const HEADING = "heading";
const PARAGRAPH = "paragraph";
const LINK = "link";
const IMAGE = "image";

export class Article extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			title: props.article.title,
			articleBase: "",
			uuid: "",
			fullImageUrl: "",
			items: [],
			is_expanded: props.expand_state || false,
			expand_callback: props.expand_callback,
		}

		let url = new URL(window.location);

		this.hostname = url.hostname;
		this.pathname = url.pathname;
		this.search = url.searchParams.get("a");
		this.urlPrefix = window.location.protocol + "//" + this.hostname + "/";
		this.baseImageUrl = this.urlPrefix + Config.getImageUrl()
		this.is_selected = props.is_selected;

	}

	componentDidMount() {

		if (true === this.is_selected || this.state.title === this.search ) {
			this.toggle();
		} else {
			this.setState({is_expanded: false,});
		}

	}

	componentWillUpdate (nextProps, nextState) {

		if ( false === nextProps.expand_state && true === nextState.is_expanded ) {
			this.setState( { is_expanded: nextProps.expand_state, } );
		}

	}

	toggle = (event) => {
		let is_expanded = !this.state.is_expanded;
		this.setState( { is_expanded: !this.state.is_expanded } );

		if ( 0 === this.state.items.length ) {
			this.prepRetrieveArticle();
		}

		setTimeout( () => this._heading.scrollIntoView( 
			{ behavior: "smooth", }), 500 );

		if ( true === is_expanded ) {
			this.state.expand_callback(this.state.title);
			//window.history.pushState("","", `${this.pathname}?a=${this.state.title}`);
		} else {
			//window.history.pushState( "", "", this.pathname );
		}

	}

	prepRetrieveArticle = () => {
		let url = this.urlPrefix + Config.getArticlesUrl() + "?select=" +
			this.props.article.uuid;

		let options = {
			method: "GET",
		};

		let request = new Request(url, options);
		this.retrieveArticle(request);
	}

	async retrieveArticle(request) {

		try {
			let response = await fetch(request);

			if (response.ok) {
				let article = await response.json();
				let items = [...article.headings, ...article.paragraphs, 
					...article.links, ...article.images];

				items = items.sort((a,b) => parseInt(a.sequence - b.sequence ));

				this.setState(
					{
						articleBase: article.articleBase,
						uuid: article.uuid,
						fullImageUrl: this.baseImageUrl + article.articleBase + 
							article.uuid + "/",
						items: items,
					}
				);

			} else {
				let errorMessage = await response.text();
				console.error(errorMessage);
				this.setState( {is_expanded: false} );
			}

		}

		catch(exc) {
			console.error(exc.toString());
				this.setState( {is_expanded: false} );
		}

	}	

	setInnerHTML = (element, article) => {

		if ( null !== element ) {
			let value = article.value.replace(/\n/g, "<br/>");

			if ( PARAGRAPH === article.type ) {
				element.innerHTML = "<div style='width: 100%'>" + value + "</div>";
			}
			else {
				element.innerHTML = "<div>" + value + "</div>";
			}

		}
	
	}

	setInnerHTMLBullet = (element, article) => {

		if ( null !== element ) {
			let value = article.value.replace(/\n/g, "<br/>");
			element.innerHTML = "&bull;&nbsp;" + value.trim();
		}
	
	}

	render() {
		let self = this;

		let articleContent = null;
		let indicator = '+';

		let articleTitleClass = "articleTitle col-10";

		if ( "News" === this.props.article.title ) {
			articleTitleClass = articleTitleClass + " articleNewsTitle";
		}

		articleTitleClass = articleTitleClass + " articleNewsTitleClass";

		if ( this._content) {
			this._content.style.height = "0vh";
		}

		if ( true === this.state.is_expanded ) {
			indicator = <span>&ndash;</span>
			const headingClass = "articleDisplayHeading articleCentered";
			const paragraphClass = "articleDisplayParagraph";

			articleContent = this.state.items.map( article => {
					let component = <div></div>;

					if (HEADING === article.type) {
						let tmpHeadingClass = headingClass;

						if ( 1 === parseInt(article.is_with_bullet)) {
							tmpHeadingClass = "articleDisplayHeading articleLeft";
							component = <div className={tmpHeadingClass} 
								key={article.sequence}
								ref={element => this.setInnerHTMLBullet(element, article)}>
							</div>
						} else {
							component = <div className={headingClass} key={article.sequence}
								ref={element => this.setInnerHTML(element, article)}>
							</div>
						}

					} else if (PARAGRAPH === article.type) {
						
						let tmpParagraphClass = paragraphClass;

						if ( 1 === parseInt(article.is_centered) ) {
							tmpParagraphClass += " articleDisplayParagraphCentered";
						}

						if ( 1 === parseInt(article.is_bordered) ) {
							tmpParagraphClass += " articleDisplayParagraphBordered";
						}

						component = <div className={tmpParagraphClass} 
							key={article.sequence}
								ref={ element => this.setInnerHTML(element, article)}>
						</div>

					} else if (LINK === article.type) {
						component = <a className="articleDisplayLink" key={article.sequence}
							href={article.url} target="_blank"
							rel="noopener noreferrer">
								{article.link_label}
							</a>
					} else if (IMAGE === article.type) {
						let imgUrl = 
							this.state.fullImageUrl + encodeURI(article.file_name);

						let width = article.width + "%";

						component = <div className="articleDisplayImageContainer"
							key={article.sequence}>
								<img width={width} src={imgUrl} alt={imgUrl}
									className="notAllowed">
									</img>
							</div>
					}

					return component;
				}
			);

			if ( this._content) {
				this._content.style.height = "auto";
			}

		}


		return(
			
			<div className="article">

				<div className="articleHeading bgcomp row"
					onClick={this.toggle}
						ref={element => self._heading = element}>

					<div className="indicatorContainer col-1">
						{indicator}
					</div>

					<div className={articleTitleClass}>
						{this.props.article.title}
					</div>

					<div className="indicatorContainer col-1">
					</div>

				</div>

				<div className="articleContent"
					ref={element => self._content = element}>
					{ articleContent && articleContent }
				</div>

			</div>
		);

	}

}
