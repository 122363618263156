import React from "react";

import "./type.css";
import "./chapter.css";
import {Item} from "./item.js";
//import {Config} from "../../config/config.js";

const CHAPTER = "chapter";

const SELECTED_ANY_CLASS = "selectedAnyItem";

export class Chapter extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			books: props.books,
			standardCallback: props.standardCallback,
			book: "",
			chapter_num: "",
			chapter_count: 0,
		}

		let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";
	}

	search = () => {
		//console.log("search()");
		//console.log(this.state.book);
		//console.log(this.state.chapter_num);

		if ( this.state.book.length > 0 && this.state.chapter_num > 0 ) {
			this.state.standardCallback( CHAPTER, this.state.book, 
				this.state.chapter_num);
		}

	}


	onChangeBook = (book_name) => {
		//console.log("onChangeBook()");
		//console.log(event.target.value);
		//let book_name = event.target.value;

		this.setState( 
				{
					book: book_name,
					chapter_num: "",
					chapter_count: 0,
				} 
			);

		for ( let i in this.state.books ) {
			let book = this.state.books[i];

			if ( book.book_name === book_name ) {
				//console.log("book_name: " + book_name );
				//console.log( "chapter_count: " + book.chapter_count);

				setTimeout( () => this.setState( 
					{ 
						chapter_count: book.chapter_count
					} 
				), 100 );

				break;
			}

		}

	}


	onChangeChapter = (chapter_num) => {
		//console.log("onChangeChapter()");

		this.setState( 
			{
				chapter_num: chapter_num,
			} 
		);

	}


	render() {

		let chapters = [];

		for ( let i = 1; i <= this.state.chapter_count; ++i ) {
			chapters.push(i);
		}

		return(
			<div className="typeContainer">
				<div className="typeContainerHeading">
					Search By Chapter
				</div>

				<div className="chapterRow">

					<div className="chapterBookBox">
						<div className="chapterLabel">Book</div>
						<div className="chapterSelectedValue">{this.state.book}</div>

						<div className="bookSelectBox">

						{ this.state.books.map( (book, key) => 
							<Item key={key} value={book.book_name} 
								selectCallback={this.onChangeBook}
								class_name="selectItem" selected_class={SELECTED_ANY_CLASS}/>
							)
						}

						</div>

					</div>

					<div className="chapterChapterBox">
						<div className="chapterLabel">Chapter</div>
						<div className="chapterSelectedValue">
							{this.state.chapter_num}
						</div>

						<div className="chapterSelectBox">

							{
								chapters.map( (chapter_num, key) =>
									<Item key={key} value={chapter_num} 
										selectCallback={this.onChangeChapter}
									class_name="selectItem" selected_class={SELECTED_ANY_CLASS}/>
								)

							}

						</div>

					</div>

				</div>

				<div className="chapterSearchButtonContainer">
					<button className="chapterSearchButton" onClick={this.search}>
						Search</button>
				</div>

			</div>
		);

	}

}

