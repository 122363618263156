const HOME_TAB_ID = "homeTab";
const HOME_MENU_ITEM_ID = "homeMenuItem";
const DROPDOWN_CLASS = "dropDownClass";

let showContactUsCallback = null;
let isShown = false;

export class SharedData {

	static getHomeTabId() {
		return HOME_TAB_ID;
	}

	static getHomeMenuItemId() {
		return HOME_MENU_ITEM_ID;
	}

	static getDropDownClass() {
		return DROPDOWN_CLASS;
	}

	static setShowContactUsCallback = (showContactUsCallbackFunction) => {
		showContactUsCallback = showContactUsCallbackFunction;
	}

	static showContactUs = () => {

		if ( false === isShown ) {
			isShown = true;
			showContactUsCallback();
		}

	}

	setIsShown() {
		isShown = true;
	}

}
