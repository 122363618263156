import React from "react";

import "./type.css";
import "./word.css";
import {SelectedWord} from "./selectedWord.js";


const WORD = "word";

export class Word extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			spec1: [],
			spec2: [],
			standardCallback: props.standardCallback,
			word_included: "",
			word_excluded: "",
			disabled: false,
		}

	}


	onSearch = () => {
		//console.log("onSearch()");

		if ( this.state.spec1.length > 0 ) {
			this.state.standardCallback( WORD, this.state.spec1, this.state.spec2 );
		} else {
			this.setState( { disabled: true } );

			setTimeout( () => this.setState( {disabled: false} ), 2000 );
		}

	}

	onChangeIncluded = (event) => {
		//console.log("onChangeIncluded()");
		let value = event.target.value.trim();

		this.setState( { word_included: value } );
	}


	onChangeExcluded = (event) => {
		//console.log("onChangeExcluded)");
		let value = event.target.value.trim();

		this.setState( { word_excluded: value } );
	}


	removeIncludedCallback = (word) => {
		//console.log("removeIncludedCallback()");
		//console.log(word);
		let spec1 = [];

		for ( let i in this.state.spec1 ) {
			let tmp = this.state.spec1[i];

			if ( tmp === word ) {
				//console.log("removing" + word);
				continue;
			} else {
				//console.log("keeping" + tmp);
				spec1.push(tmp);
			}

		}

		this.setState( {spec1: []} );

		setTimeout( () => this.setState( {spec1: spec1} ), 100);

	}


	addIncludedWord = () => {
		//console.log("addIncludedWord()");

		if ( this.state.word_included.length > 0 &&
			this.shouldAdd(this.state.word_included) ) {
			let spec1 = this.state.spec1;
			spec1.push(this.state.word_included);

			spec1 = spec1.sort();

			this.setState( {spec1: [], word_included: ""} );

			setTimeout( () => this.setState( 
				{
					spec1: spec1,
				} 
			), 100);

		}

	}


	addExcludedWord = () => {
		//console.log("addExcludedWord()");

		if ( this.state.word_excluded.length > 0 &&
			this.shouldAdd(this.state.word_excluded) ) {
			let spec2 = this.state.spec2;

			spec2.push(this.state.word_excluded);
			spec2 = spec2.sort();
			this.setState( 
				{
					spec2: [],
					word_excluded: "",
				} 
			);

			setTimeout( () => this.setState( { spec2: spec2 } ), 100 );

			//console.log("adding: " + this.state.word_excluded);

		}

	}


	shouldAdd = (word) => {
		let shouldAdd = true;

		let allWords = [...this.state.spec1, ...this.state.spec2]

		for ( let i in allWords ) {

			if ( word === allWords[i] ) {
				shouldAdd = false;
				break;
			}

		}

		return shouldAdd;
	}


	removeExcludedCallback = (word) => {
		//console.log("removeExcludedCallback()");
		//console.log(word);

		let spec2 = [];

		for ( let i in this.state.spec2 ) {
			let tmp = this.state.spec2[i];

			if ( tmp === word ) {
				//console.log("removing" + word);
				continue;
			} else {
				//console.log("keeping" + tmp);
				spec2.push(tmp);
			}

		}

		this.setState( {spec2: []} );

		setTimeout( () => this.setState( {spec2: spec2} ), 100);
	}


	render() {

		let self = this;

		return(
			<div className="typeContainer">

				<div className="typeContainerHeading">
					Search by Included and Excluded Words
				</div>

				<div className="wordContainer">

					<div className="wordListBox">

						<div className="wordHeading">Included Words</div>

						<div className="wordAddRow">

							<input type="text" className="wordAddInput"
								value={this.state.word_included}
								onChange={this.onChangeIncluded} 
								ref={ element => self._inputIncluded = element } />

							<button className="wordAddButton" 
								title="Add to Included List"
									onClick={this.addIncludedWord} >Add</button>

						</div>

						<div className="wordSelected">
						{
							this.state.spec1.map( (word, key) =>
								<SelectedWord key={key} word={word} 
									remove_callback={this.removeIncludedCallback}/>
							)
						}
						</div>

					</div>

					<div className="wordListBox">
						<div className="wordHeading">Excluded Words</div>

						<div className="wordAddRow">

							<input type="text" className="wordAddInput"
								value={this.state.word_excluded}
								onChange={this.onChangeExcluded} 
								ref={ element => self._inputExcuded = element } />

							<button className="wordAddButton" 
								title="Add to Excluded List"
									onClick={this.addExcludedWord} >Add</button>

						</div>

						<div className="wordSelected">
						{
							this.state.spec2.map( (word, key) =>
								<SelectedWord key={key} word={word} 
									remove_callback={this.removeExcludedCallback}/>
							)
						}
						</div>

					</div>
	
					<button className="wordSearchButton"
						onClick={this.onSearch} title="Search">Search</button>

				</div>
			
			</div>
		);

	}

}

