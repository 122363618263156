import React from "react"; 
import "./search.css";
import {Config} from "../../config/config.js";
import {Word} from "./word.js";
import {Reference} from "./reference.js";
import {Part} from "./part.js";
import {Exact} from "./exact.js";
import {Chapter} from "./chapter.js";
import {Content} from "./content.js";
import {makeStandardVerse} from "./standardVerse.js";
import {makeChapterVerse} from "./chapterVerse.js";

const TYPE = "type";

const SPEC1 = "spec1";
const SPEC2 = "spec2";
const SPEC3 = "spec3";

const WORD = "word";
const REFERENCE = "reference";
const PART = "part";
const EXACT = "exact";
const CHAPTER = "chapter";

const STARTS_WITH = "starts_with";
const CONTAINS = "contains";

export class Search extends React.Component {

	static highLight(verse, exp, trailing) {
		let verse2 = verse;
		let matches = verse.matchAll(exp);

		if ( matches ) {

			for ( let match of matches ) {
				let found = match[1];
				verse2 = verse2.replaceAll(exp, 
					"<span style='color: #b000ff; font-weight: bold;'>" + 
					found + trailing + "</span>");
			}

		}

		return verse2;
	}

	constructor(props) {
		super(props);

		this.state = {
			heading: props.heading,
			database: props.database,
			type: "",
			display_content: false,
			content_heading: "",
			books: {},
			verse_list: [],
			factory: makeStandardVerse,
		}

    let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";
	}


	componentDidMount() {
		let url = this.urlPrefix + Config.getSearchUrl() + '/?request=books';

		let options = {
			method: "GET",
		};

		let request = new Request(url, options);

		this.getBooksAndChapters(request);
	}


	async getBooksAndChapters(request ) {

		try {
			let response = await fetch(request);

			if (response.ok ) {
				let data =  await response.json();
				//console.log(data);
				this.setState( {books: data} );
			} else {
				let text = await response.text();
				console.error(text);
			}

		}

		catch(exc){
			console.error(exc);
		}

	}


	componentWillUnmount() {
	}

	typeSelect = (event) => {
		//console.log("typeSelect()");
		let value = event.target.value;
		//console.log(value);

		let factory = makeStandardVerse;

		if ( CHAPTER === value ) {
			factory = makeChapterVerse;
		}

		this.setState( 
			{
				type: value,
				factory: factory,
			}
		);
	}


	standardCallback = (type, spec1, spec2 ) => {
		//console.log("standardCallback()");
		//console.log(type)
		//console.log(spec1)

		let url = this.urlPrefix + Config.getSearchUrl();

		let formData = new FormData();

		formData.append(TYPE, type);
		formData.append(SPEC1, spec1);

		if (PART === type || WORD === type || CHAPTER === type ) {
			formData.append(SPEC2, spec2);
		}

		let options = {
			method: "POST",
			body: formData,
		};

		let request = new Request(url, options);
		this.setState( {type: type} );
		
		this.getVerses(request, spec1, spec2);
	}


	referenceCallback = (type, spec1, spec2, spec3 ) => {
		//console.log("standardCallback()");
		//console.log(type)
		//console.log(spec1)

		let url = this.urlPrefix + Config.getSearchUrl();

		let formData = new FormData();

		formData.append(TYPE, type);
		formData.append(SPEC1, spec1);
		formData.append(SPEC2, spec2);
		formData.append(SPEC3, spec3);

		let options = {
			method: "POST",
			body: formData,
		};

		let request = new Request(url, options);
		this.setState( {type: type} );
		this.getVerses(request);
	}


	async getVerses(request, spec1, spec2, spec3) {
		
		try {
			let response = await fetch(request);

			if ( response.ok ) {
				let data = await response.json();

				/*
				console.log("type " + this.state.type);
				console.log("spec1 " + spec1);
				console.log("spec2 " + spec2);
				console.log("spec3 " + spec3);
				*/

				let content_heading = "";

				if ( REFERENCE !== this.state.type ) {

					if ( CHAPTER === this.state.type ) {

						if ( data.verse_list.length > 0 ) {
							let verse = data.verse_list[0];
							content_heading = verse.book_name.replace('Psalms', 'Psalm') + 
								" " + verse.chapter_num;
						}

					} else {
						content_heading = data.verse_list.length + " verses";
					}

				} 

				let i = 0;

				for ( i in data.verse_list ) {
					data.verse_list[i].book_name = 
						data.verse_list[i].book_name.replace('Psalms', 'Psalm');
				}

				if ( EXACT === this.state.type ) {
					let exp = new RegExp("\\b(" + spec1 + ")", "gi");
					
					i = 0;

					for ( i in data.verse_list ) {
						data.verse_list[i].verse = 
							Search.highLight(data.verse_list[i].verse, exp, "");
					}

				 } else if ( PART === this.state.type ) {
				 		//console.log("PART");

						if (CONTAINS === spec2 ) {
							//console.log("CONTAINS");
							let exp = new RegExp( "(" + spec1 + ")", "gi");

							i = 0;

							for ( i in data.verse_list ) {
								data.verse_list[i].verse = 
									Search.highLight(data.verse_list[i].verse, exp, "");
							}

						} else {

							if ( STARTS_WITH === spec2 ) {
								//console.log("STARTS_WITH");
								let exp = new RegExp( "\\b(" + spec1 + ")", "gi");

								i = 0;

								for ( i in data.verse_list ) {
									data.verse_list[i].verse = 
										Search.highLight(data.verse_list[i].verse, exp, "");
								}

							} else {
								// ends_with
								//console.log("ENDS_WITH");
								let exp = new RegExp( "(" + spec1 + ")[ ,.:;?]", "gi");

								i = 0;

								for ( i in data.verse_list ) {
									data.verse_list[i].verse = 
										Search.highLight(data.verse_list[i].verse, exp, " ");
								}

							}

						} 

				 } else if ( WORD === this.state.type ) {
					let specList = [];
					let spec = "";

					for ( spec of spec1 ) {
						specList.push(spec.replace( ',', '' ).trim());
					}
					i = 0;

					for ( i in data.verse_list ) {
						let spec = "";

						for ( spec of specList ) {
							let exp = new RegExp( "\\b(" + spec + ")[ .,:;?]", "gi");

							data.verse_list[i].verse = 
								Search.highLight(data.verse_list[i].verse, exp, " ");
						}
					
					}

				}

				this.setState( 
					{
						verse_list: data.verse_list,
						display_content: true,
						content_heading: content_heading,
					} 
				);

			} else {
				let text = await response.text();
				console.error(text);
			}

		}

		catch(exc) {
			console.error(exc);
		}

	}


	closeCallback = () => {
		this.setState( {display_content: false, } );
	}


	render() {

		return(
			<div className="search">

				{
					this.state.display_content &&
					<Content contentHeading={this.state.content_heading}
						verseList={this.state.verse_list} 
						closeCallback={this.closeCallback} 
							factory={this.state.factory} />
				}

				<div className="searchHeading">{this.state.heading}</div>
				<div className="searchBy">Search by . . .</div>

				<div className="searchType">

					<div className="searchByBox">
						<div className="searchTypeLabel">Words:</div>
						<input className="searchTypeRadio"type="radio" name="searchType" 
							value="word" onClick={this.typeSelect}/>
					</div>

					<div className="searchByBox">
						<div className="searchTypeLabel">Word Part:</div>
						<input className="searchTypeRadio" type="radio" name="searchType"
							value="part" onClick={this.typeSelect}/>
						</div>

					<div className="searchByBox">
						<div className="searchTypeLabel">Phrase:</div>
						<input className="searchTypeRadio" type="radio" name="searchType"
							value="exact" onClick={this.typeSelect}/>
					</div>

					<div className="searchByBox">
						<div className="searchTypeLabel">Reference:</div>
						<input className="searchTypeRadio" type="radio" name="searchType"
							value="reference" onClick={this.typeSelect}/>
					</div>

					<div className="searchByBox">
						<div className="searchTypeLabel">Chapter:</div>
						<input className="searchTypeRadio" type="radio" name="searchType"
							value="chapter" onClick={this.typeSelect}/>
					</div>

				</div>

				{
					WORD === this.state.type &&
						<Word standardCallback={this.standardCallback} />
				}

				{
					REFERENCE === this.state.type &&
						<Reference books={this.state.books} 
							referenceCallback={this.referenceCallback}/>
				}

				{
					PART === this.state.type &&
						<Part standardCallback={this.standardCallback} />
				}

				{
					EXACT === this.state.type &&
					<Exact standardCallback={this.standardCallback} />
				}

				{
					CHAPTER === this.state.type &&
					<Chapter books={this.state.books} 
						standardCallback={this.standardCallback}/>

				}

			</div>

		);

	}

}
