import React from "react";

import "./type.css";
import "./exact.css";

const EXACT = "exact";

export class Exact extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			text: "",
			standardCallback: props.standardCallback,
			disabled: false,
		}

	}

	onTextChange = (event) => {
		//console.log("inTextChange()");
		let value = event.target.value;
		//console.log(value);

		this.setState({text: value});
	}

	onSearch = () => {
		//console.log("onSearch()");
		let text = this.state.text.trim().replaceAll(/ {2,}/g, " ");
		//console.log("text: " + text);

		if ( text.length > 0 ) {
			this.state.standardCallback(EXACT, text);
			setTimeout(() => this.setState({text: text}), 0 );
		} else {
			//console.log("disabling");
			this.setState( {text: ""} );
				
			const disableButton = () => {
				this.setState( {disabled: true} );
				setTimeout( () => this.setState( { disabled: false } ), 2000 );
			}

			disableButton();

		}

	}

	render() {

		return(
			<div className="typeContainer">

				<div className="typeContainerHeading">Search by Phrase</div>

				<div className="exactContainer">

					<div className="exactFieldLabel">Enter a phrase to find:</div>
					<input className="exactText" type="text" value={this.state.text}
						onChange={this.onTextChange}></input>

					<div className="exactButtonContainer">
						<button className="exactButton"
							onClick={this.onSearch} disabled={this.state.disabled} >
								Search
						</button>
					</div>

				</div>
			
			</div>
		);

	}

}

