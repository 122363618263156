import React from "react";

import "./item.css";

export class Item extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			value: props.value,
			class_name: props.class_name,
			selected_class: props.selected_class,
			current_class: props.class_name,
			selectCallback: props.selectCallback,
		}

	}

	onSelect = () => {
		//console.log("onSelect()")
		//console.log(this.state.value)
		this.state.selectCallback(this.state.value);

		this.setState(
			{
				current_class: this.state.class_name + " " + this.state.selected_class,
			}
		);

		const setCurrent = () => this.setState( 
			{current_class: this.state.class_name} );

		setTimeout(setCurrent, 500);

	}


	render() {

		return(
			<div className={this.state.current_class} 
				onClick={this.onSelect}>{this.state.value}</div>
		);

	}

}

