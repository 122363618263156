import React from "react";

import "./type.css";
import "./part.css";

const PART = "part";

const STARTS_WITH = "starts_with";
const CONTAINS = "contains";
const ENDS_WITH = "ends_with";

export class Part extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			text: "",
			spec2: "",
			standardCallback: props.standardCallback,
			disabled: false,
		}

	}

	onTextChange = (event) => {
		//console.log("inTextChange()");
		let value = event.target.value.trim();
		//console.log(value);

		this.setState({text: value});
	}

	onPartSelect = (event) => {
		//console.log("onPartSelect()");
		//console.log(event.target.value);

		this.setState( {spec2: event.target.value} );
	}

	onSearch = () => {
		//console.log("onSearch()");


		if ( this.state.text.length > 0 && this.state.spec2.length > 0 ) {
			this.state.standardCallback(PART, this.state.text, this.state.spec2);
		} else {
			//console.log("disabling");
				
			const disableButton = () => {
				this.setState( {disabled: true} );
				setTimeout( () => this.setState( { disabled: false } ), 2000 );
			}

			disableButton();

		}

	}

	render() {

		return(
			<div className="typeContainer">

				<div className="typeContainerHeading">Search by Word Part</div>

				<div className="partContainer">

					<div className="partRadioRow">

						<div className="partRadioBox">
							<div className="partRadioLabel">Starts With:</div>
							<input type="radio" className="partRadio" 
								name="part" value={STARTS_WITH} onClick={this.onPartSelect} />
						</div>

						<div className="partRadioBox">
							<div className="partRadioLabel">Contains:</div>
							<input type="radio" className="partRadio" 
								name="part" value={CONTAINS} onClick={this.onPartSelect}/>
						</div>

						<div className="partRadioBox">
							<div className="partRadioLabel">Ends With:</div>
							<input type="radio" className="partRadio" 
								name="part" value={ENDS_WITH} onClick={this.onPartSelect} />
						</div>

					</div>

					<div className="partFieldLabel">Enter a Word Part:</div>
					<input className="partText" type="text" value={this.state.text}
						onChange={this.onTextChange}></input>
					<button className="partButton"
						onClick={this.onSearch} disabled={this.state.disabled} >
							Search
						</button>

				</div>
			
			</div>
		);

	}

}

