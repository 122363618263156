import {SharedData} from "../sharedData/sharedData.js";

export class Utils {

	static hideAllDropDowns() {
		//console.log("hideAllDropDowns()");

		document.querySelectorAll( "." + SharedData.getDropDownClass() ).forEach(
			dropDown => {
				dropDown.style.display = "none";
			}
		);

	}

	static slideMenuOut() {
	}

	static setSlideMenuOutFunction(slideMenuOutFunction) {
		//console.log("setSlideMenuOutFunction()")
		Utils.slideMenuOut = slideMenuOutFunction;
	}

}
