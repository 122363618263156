import React from "react";
import {Config} from "../../config/config.js";
import "./songbook.css";
import {Song} from "./song.js";

const ENTER = "Enter"

const SELECTED_SONG = "selectedSong";
const DISPLAYED_SONG = "selectedSong displayedSong";

export class SongBook extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			songbookConfig: "",
			entry: "",
			selectedTitle: "",
			selectedNumber: "",
			displayList: [],
			displayClass: SELECTED_SONG,
			displayContent: "",
		}

		this.fullList = [];

	}

	componentDidMount() {
		this.songbookUrl = Config.getSongBookUrl();
		let songbookConfig = Config.getSongBookConfig();
		let url = this.songbookUrl + "/" + songbookConfig;

		//console.log("<<<" + url + ">>>");

		let options = {
			method: "GET",
		}

		let request = new Request(url, options);

		this.getConfig(request);
		//console.log(this.constructor.name);
	}

	async getConfig(request) {

		try {
			let response = await fetch(request);

			if ( response.ok ) {
				let data = await response.json();
				//console.log(data);

				let displayList = []

				for ( let i = 0; i < data.song_objects.length; ++i) {
						displayList.push(i)
				}

				this.setState(
					{ 
						songbookConfig: data,
						displayList: displayList,
					},
				);

				for ( let i = 0; i < data.song_objects.length; ++i ) {
					this.fullList.push(data.song_objects[i]);
				}

			} else {
				let text = response.text()
				console.error(text);
			}

		}

		catch(exc) {
			console.error(exc);
		}

	}

	search = (toCompare) => {
		let displayList = [];

		for ( let i = 0; i < this.state.songbookConfig.song_objects.length; 
			++i ) {
			let song = this.state.songbookConfig.song_objects[i];

			for ( let j = 0; j < song.search.length; ++j ) {
				let search = song.search[j];

				if ( search.startsWith(toCompare) ) {
					displayList.push(i);
					break;
				}

			}

		}

		return displayList;
	}

	populateList = (event) => {
		let value = event.target.value;

		let textAdded = value.length > this.state.entry.length;

		let toCompare = value.toLowerCase().trim();
		let displayList = [];

		this.setState( 
			{ 
				entry: value, 
				displayList: [],
			} 
		);

		if ( toCompare.length > 0 ) {
			displayList = this.search(toCompare);
		} else {
		
			for ( let i = 0; i < this.fullList.length; ++i ) {
				displayList.push(i);
			}

		}

		setTimeout( () =>
			this.setState( 
				{ 
					displayList: displayList,
				} 
			), 200 );

		//console.log(displayList.length);
		//console.log(textAdded);

		if ( 1 === displayList.length && textAdded ) {
			let title = this.state.songbookConfig.song_objects[displayList[0]].title;
			setTimeout( ()=>
				this.setState(
					{ 
						entry: title,
					}
				), 250 );
		}

	}

	entryClick = (event) => {
		//console.log("entryClick()");
		let value = event.target.value;
		//console.log(value);
		let toCompare = value.toLowerCase().trim();

		if ( toCompare.length > 0 ) {
			let displayList = this.search(toCompare);

			if ( 1 === displayList.length ) {
				let index = displayList[0];
				this.selectCallback(index);
			}

		}

	}

	entryKeyPress = (event) => {
		//console.log("entryKeyPress()");
		let key = event.key;
		//console.log(key);

		if ( key === ENTER ) {
			let toCompare = event.target.value.toLowerCase().trim();

			if ( toCompare.length > 0 ) {
				let displayList = this.search(toCompare);

				if ( 1 === displayList.length ) {
					let index = displayList[0];
					this.selectCallback(index);
				}

			}

		}

	}

	selectCallback = (index) => {
		//console.log("selecteCallback() " + index);

		let selectedSong = this.state.songbookConfig.song_objects[index];

		setTimeout( () =>  this.setState(
			{
				selectedTitle: selectedSong.title,
				selectedNumber: selectedSong.num,
			}
		), 0 );

		let url = this.songbookUrl + "/" + selectedSong.file;

		let options = {
			method: "GET",
		};

		let request = new Request(url, options);

		this.retrieveSongContent(request);
	}

	async retrieveSongContent(request) {

		try {
			let response = await fetch(request);

			if ( response.ok ) {
				let songContent = await response.text();

				//console.log("<<<" + songContent + ">>>");

				let lineList = songContent.split("\n");
				lineList.shift();
				lineList.shift();
				let displayContent = lineList.join("<br/>");
				//console.log(displayContent);

				this.setState(
					{
						displayClass: DISPLAYED_SONG,
						displayContent: displayContent,
					}
				);

				setTimeout( () => { this._display.innerHTML = displayContent;
					this._display.focus();}, 0 );
				//setTimeout(()  => alert(this.state.displayContent), 1000);

			} else {
				let error = response.text();
				console.error(error);
				alert(error);
			}

		}

		catch (exc) {
			console.error(exc);
		}

	}

	clearDisplay = () => {
		let displayList = [];

		for ( let i = 0; i < this.state.songbookConfig.song_objects.length; ++i) {
				displayList.push(i)
		}

		this._display.innerHTML = "";
		
		this.setState(
			{
				entry: "",
				selectedTitle: "",
				selectedNumber: "",
				displayList: displayList,
				displayClass: SELECTED_SONG,
				displayContent: "",
			}
		);

		//setTimeout( () => this._input.blur(), 0 );
		setTimeout( () => this._input.focus(), 0 );
	}

	stopProp = (event) => {
		//console.log("stopProp()");
		event.stopPropagation();
	}

	render() {
		let self = this;

		return(
			<div className="songBook">
				<div className={this.state.displayClass}>
					<div className="displayTop">
						<div className="displaySide">{this.state.selectedNumber}</div>
						<div className="displayTitle">{this.state.selectedTitle}</div>
						<div className="displayX" title="Close"
							onClick={this.clearDisplay}>X</div>
					</div>
					<div className="displayVerses"
						onScroll={this.stopProp}
						ref={element => self._display = element}></div>
				</div>

				<h3 className="songBookHeading">Song Book</h3>
				<div className="songBookInst">
					Enter song number or title, click title
				</div>
				<input type="text" className="songBookEntry" value={this.state.entry}
					onChange={this.populateList} onClick={this.entryClick}
					onKeyPress={this.entryKeyPress} 
					ref={ element => self._input = element}/>

				<div className="songBookList">
				{this.state.displayList.map( (i) =>
					<Song key={i} 
						title={this.state.songbookConfig.song_objects[i].title}
						num={this.state.songbookConfig.song_objects[i].num}
						index={i} callback={this.selectCallback} />
					
				)}
				</div>
			</div>
		)

	}

}
