const TEXT_EXP1 = new RegExp(/^[A-Z,a-z-]+$/);
const TEXT_EXP2 = new RegExp(/select/, "i");
const TEXT_EXP3 = new RegExp(/update/, "i");
const TEXT_EXP4 = new RegExp(/delete/, "i");
const TEXT_EXP5 = new RegExp(/insert/, "i");
const TEXT_EXP6 = new RegExp(/replace/, "i");

const MSG_EXP1 = new RegExp(/[{}[\]]/);
const MSG_EXP2 = new RegExp(/let.+=/);

const LANG_EXP1 = new RegExp(/fuck/, "i");
const LANG_EXP2 = new RegExp(/pussy/, "i");
const LANG_EXP3 = new RegExp(/cunt/, "i");
const LANG_EXP4 = new RegExp(/suck/, "i");
const LANG_EXP5 = new RegExp(/kill/, "i");
const LANG_EXP6 = new RegExp(/alla/, "i");
const LANG_EXP7 = new RegExp(/damn/, "i");

const EMAIL_EXP =
	new RegExp("^[A-Za-z_0-9]{2,}[.]?[A-Za-z0-9_.]*@[A-Za-z0-9_]{2,}[.]?[A-Za-z0-9_.]*[.][A-Za-z]{2,}$");

export class Checks {

	static isLanguageOK(text) {
		let OK = true;;

		if ( text.match(LANG_EXP1) ||
					text.match(LANG_EXP2) ||
					text.match(LANG_EXP3) ||
					text.match(LANG_EXP4) ||
					text.match(LANG_EXP5) ||
					text.match(LANG_EXP6) ||
					text.match(LANG_EXP7) ) {
			OK = false;
		}

		return OK;
	}

	static isNameOK(name) {
		let OK = false;

		if ( name.match(TEXT_EXP1) ) {
			OK = true;

			if ( name.match(TEXT_EXP2) ||
						name.match(TEXT_EXP3) || 
						name.match(TEXT_EXP4) || 
						name.match(TEXT_EXP5) || 
						name.match(TEXT_EXP6) || 
						false === Checks.isLanguageOK(name) ) {
				OK = false;
			}

		}

		return OK;
	}

	static isSubjectOK(subject) {
		let OK = true;

		if ( subject.match(TEXT_EXP2) ||
					subject.match(TEXT_EXP3) || 
					subject.match(TEXT_EXP4) || 
					subject.match(TEXT_EXP5) || 
					subject.match(TEXT_EXP6) || 
					false === Checks.isLanguageOK(subject) ) {
			OK = false;
		}


		return OK;
	}

	static isMessageOK(message) {
		let OK = true;

		if (message.match(MSG_EXP1) || message.match(MSG_EXP2) ||
			false === Checks.isLanguageOK(message) ) {
			OK = false;
		}

		return OK
	}

	static isEmailOK(email) {
		let OK = true;

		//console.log(email.match(EMAIL_EXP));

		if ( null === email.match(EMAIL_EXP) ||  
			email.match(LANG_EXP1) ||
			email.match(LANG_EXP2) ||
			email.match(LANG_EXP3) ||
			email.match(LANG_EXP4) ||
			email.match(LANG_EXP5) ||
			email.match(LANG_EXP6) ||
			email.match(LANG_EXP7) ||
			email.match(TEXT_EXP2) ||
			email.match(TEXT_EXP3) ||
			email.match(TEXT_EXP4) ||
			email.match(TEXT_EXP5) ||
			email.match(TEXT_EXP6)) {
			OK = false;
		}

		return OK;
	}

}
