import React from "react";
import "./slideMenu.css";
import {Hamburger} from "./hamburger.js"; 
import {MenuCategory} from "./menuCategory.js";
import {Utils} from "../../utils/utils.js";

export class SlideMenu extends React.Component {

	constructor(props) {
		super(props) 

		this.navigation = props.navigation;
		Utils.setSlideMenuOutFunction(this.slideOut);
	}

	slideOut = () => {
		//console.log("slideOut()");
		this._menu.style.left = "-350px";
		this.isMenuIn = false;
	}

	toggleInOut = () => {
		//console.log( "toggleInOut()" );

		if ( true === this.isMenuIn ) {
			this._menu.style.left = "-350px";
		} else {
			this._menu.style.left = "2%";
		}

		this.isMenuIn = !this.isMenuIn;
	}

	render() {
		let self = this;

		return (
			<div id="leftSlideMenu" className="slideMenu bordered"
				ref={
					(element) => self._menu = element
				} onClick={this.toggleInOut}>
				<Hamburger />
				{
					this.navigation.groups.map( (group, index) =>
						<MenuCategory key={group.routes[0].path} index={index} 
						category={group.category} routes={group.routes}/>
					)
				}
			</div>
		);

	}

}
