import React from "react";
import "./contactUs.css";
import {Config} from "../../config/config.js";
import {Checks} from "../../utils/checks.js";

const MAX_ATTACHMENT_SIZE = 30 * 1024 * 1024;
const MAX_ATTACHMENT_COUNT = 3;


export class ContactUs extends React.Component {

	constructor(props) {
		super(props)

		this.fileInput = <input type="file" id="newFileInput" 
								name="newFileInput" className="fgcomp" 
								multiple={false} accept="image/*" 
								onChange={this.onAttachmentChange}/>

		let tmpFileInput = {};
		tmpFileInput = Object.assign( tmpFileInput, this.fileInput );

		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			subject: "",
			message: "",
			nameRequired: "",
			emailRequired: "",
			cuPhone: "",
			validPhoneRequired: "",
			subjectRequired: "",
			statusMessage: "", 
			attachments: [],
			fileInput: tmpFileInput,
			isSendDisabled: false,
		};

		let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";

	}

	componentDidMount() {
		/*
		let url = this.urlPrefix + Config.getContactUsUrl();

		let options = {
			method: 'GET',
		};

		//console.log(url);

		let request = new Request(url, options);

		this.getPhone(request)
		*/
		document.querySelector("#cuFirstName").focus();
	}

	async getPhone(request) {

		try {
			let response = await fetch(request);

			if ( response.ok ) {
				let phone = await response.text();

				//console.log(phone);
				this.setState( {phone: phone});
			} else {
				let error = await response.text();
				console.error(error);
			}

		}

		catch(exc) {
			console.error(exc.toString());
		}

	}

	onFirstNameChange = (event) => {
		let value = event.target.value.trim();
		//console.log(value);

		if ( Checks.isNameOK(value) || 
			value.length < this.state.firstName.length ) {
			this.setState({firstName: value } );
		}

	}

	onLastNameChange = (event) => {
		let value = event.target.value.trim();
		//console.log(value);

		if ( Checks.isNameOK(value) || 
			value.length < this.state.lastName.length ) {
			this.setState({lastName: value } );
		}
	
	}

	onEmailChange = (event) => {
		let value = event.target.value.trim().toLowerCase();

		this.setState({email: value});
	}

	onPhoneChange = (event) => {
		/*
		console.log("onPhoneChange()");
		console.log(event);
		console.log(event.target);
		console.log("selectionStart " + event.target.selectionStart);
		console.log("cuPhone length " + this.state.cuPhone.length);
		*/

		let cursorPosition = event.target.selectionStart;
		let cuPhoneLength = this.state.cuPhone.length;
		let value = event.target.value.trim().slice(0, 12);

		let digits = value.replace(/-/g, '' );

		if ( value.length < this.state.cuPhone.length ) {
			let last = this.state.cuPhone[this.state.cuPhone.length - 1];

			if ( '-' === last ) {
				digits = digits.slice(0, digits.length - 1 );
			}

		}

		let onlyDigits = "";

		for ( let digitIdx = 0; digitIdx < digits.length; ++digitIdx ) {
			let d = digits[digitIdx];
			
			for ( let i = 0; i < 10; ++i ) {
				let asString = "" + i;

				if ( asString === d ) {
					onlyDigits += d;
					break;
				}

			}

		}

		let newPhone = "";

		for ( let idx = 0; idx < onlyDigits.length; ++idx ) {
			
			newPhone += onlyDigits[idx];

			if ( 2 === idx || 5 === idx ) {
				newPhone += "-";
			}
			
		}

		this.setState( {cuPhone: newPhone} );

		if ( 1 !== ( cursorPosition - cuPhoneLength ) ) {
			//console.log("set cursor");
			const target = event.target;

			if ( 3 === cursorPosition ||
				4 === cursorPosition || 
				7 === cursorPosition || 
				8 === cursorPosition  ) {
				++cursorPosition;
			}

			const setCursor = () => 
				target.setSelectionRange(cursorPosition, cursorPosition );
			setTimeout( setCursor, 0 );
		}

	}

	onSubjectChange = (event) => {
		let value = event.target.value;

		if ( Checks.isSubjectOK(value) ||
			value.length < this.state.subject.length ) {
			this.setState({subject: value} );
		}

	}

	onMessageChange = (event) => {
		let value = event.target.value;

		if ( Checks.isMessageOK(value) || 
			value.length < this.state.message.length ) {
			this.setState({message: event.target.value} );
		}

	}

	prepSend = () => {
		//console.log( "prepSend()" );
		const url = this.urlPrefix + Config.getContactUsUrl();
		//console.log( "url: " + url );

		let subject = this.state.subject.trim();
		let message = this.state.message.trim();

		let status = true;

		if ( 0 === this.state.firstName.length || 
			0 === this.state.lastName.length ) {
			//console.log("false name");
			status = false;
			this.setState( {nameRequired: " *" } );
		} else {
			//console.log("true name");
			this.setState( {nameRequired: "" } );
		}

		//if ( this.state.email.match(EMAIL_EXP) ) {
		if ( true === Checks.isEmailOK(this.state.email) ) {
			//console.log("true email");
			this.setState( {emailRequired: "" } );
		} else {
			//console.log("false email");
			status = false;
			this.setState( {emailRequired: "* " } );
		}

		if ( this.state.cuPhone.length > 0 && this.state.cuPhone.length < 12 ) {
			status = false;
			this.setState( {validPhoneRequired: " * invalid"} );
		} else {
			this.setState( {validPhoneRequired: ""});
		}

		if ( 0 === subject.length ) {
			//console.log("false subject");
			status = false;
			this.setState( {subjectRequired: " *" } );
		} else {
			//console.log("true subject");
			this.setState( {subjectRequired: "" } );
		}

		if ( true === status && message.length > 0 ) {
			//console.log("true");
			let formData = new FormData();
			formData.append('firstName', this.state.firstName );
			formData.append('lastName', this.state.lastName );
			formData.append('email', this.state.email );
			formData.append('phone', this.state.cuPhone );
			formData.append('subject', subject );
			formData.append('message', message );

			let attachmentNames = [];
			let attachmentTypes = [];

			for ( let attachment of this.state.attachments ) {
				attachmentNames.push( attachment['name'] );
				attachmentTypes.push( attachment['type'] );

				//console.log( "attachment" )
				//console.log( attachment )

				formData.append("attachment", attachment, attachment.name );
			}

			/*
			for ( let k of formData.keys() ) {
				console.log( k + " " + formData.get(k) );
			}
			*/

			formData.append( "attachmentNames", attachmentNames );
			formData.append( "attachmentTypes", attachmentTypes );

			const options = {
				method: 'POST',
				body: formData,
				//mode: 'no-cors',
			};

			let request = new Request( url, options );
			this.setState( {statusMessage: "Sending the message" });
			this.send( request );
		}

	}

	async send( request ) {
		this.setState(
			{isSendDisabled: true}
		);

		try {
			let response = await fetch( request );

			if ( response ) {
				//console.log ( "response.status: " + response.status);
				//console.log ( "response.ok: " + response.ok);
				//console.log( response );

				if ( response.ok ) {
					await response.text()
					this.setState( {statusMessage: "The message has been sent" } );
				} else {
					let error = await response.text()
					this.setState(
						{
							isSendDisabled: true,
							statusMessage: `Send error: ${error}`,
						}
					);
				}

			} else {
				console.error( "request error" );
				this.setState(
					{
						statuMessage: "The message was NOT sent successfully",
					}
				);
			}

		}

		catch (exc) {
			console.error(exc.toString);	
		}

		finally {
			setTimeout( () => this.closeContactUs(), 3000 )
		}

	}

	deleteAttachment = (attachmentName) => {
		//console.log( "deleteAttachment(): " + attachmentName );

		this.setState(
			{attachments: this.state.attachments.filter( attachment =>
				attachmentName !== attachment['name']) }
		);

	}

	closeContactUs = () => {
		//console.log( "closeContactUs()" );
		this.props.callback();
	}

	onAttachmentChange = (event) => {
		//console.log( "onAttachmentChange()" )
		let isCanceled = false;

		let attachment = event.target['files'][0];
		let name = attachment['name'];
		let size = parseInt(attachment['size'] );
		let newSize = size;

		for ( let tmp of this.state.attachments ) {
			newSize += parseInt(tmp['size']);
		}

		if ( newSize > MAX_ATTACHMENT_SIZE ) {
			isCanceled = true;

			this.setState(
				{ statusMessage: "The maximum attachment size is exceeded" }
			);

		}

		if ( MAX_ATTACHMENT_COUNT === this.state.attachments.length ) {
			isCanceled = true;

			this.setState(
				{ statusMessage: `${MAX_ATTACHMENT_COUNT} attachments are allowed` }
			);

		}

		for ( let attach of this.state.attachments ) {

			if ( name === attach.name ) {
				isCanceled = true;

				this.setState(
					{ statusMessage: name + " is already attached" }
				);

				break;
			}

		} 

		//console.log( "name: " + name + " size: " + size );

		let newFileInput = {};
		newFileInput = Object.assign( newFileInput, this.fileInput );

		if ( false === isCanceled ) {

			this.setState(
				{ 
					attachments: [...this.state.attachments, attachment],
				}
			);
		}

		const delay = () => {
			this.setState(
				{ fileInput: <div></div> }
			);

			this.setState(
				{fileInput: newFileInput},
			);

		};

		setTimeout( delay, 1500 );

		setTimeout( () => this.setState({statusMessage: "" } ), 2000 );

	}

	render() {

		return (
			<div id="contactUs">

				<div className="cuTitleRow bgbase">

					<div className="cuTopSide">
					</div>

					<div className="cuTitle">
						Contact Us
					</div>

					<div className="cuTopSide cuHideContactUsButton" 
						onClick={this.closeContactUs}>
						&times;
					</div>

				</div>

				<div className="cuInputs">

					<div className="cuInputContainer">

						<div className="cuLabel">First Name</div>
						<div className="cuTextContainer">
							<input type="text" id="cuFirstName" className="cuInput"
								value={this.state.firstName} 
								onChange={this.onFirstNameChange}/>
						</div>

						<div id="nameRequired" className="cuStatusMessage required">
						</div>

					</div>

					<div className="cuInputContainer">

						<div className="cuLabel">Last Name</div>
						<div className="cuTextContainer">
							<input type="text" id="cuLastName" className="cuInput"
								value={this.state.lastName} 
								onChange={this.onLastNameChange}/>
						</div>

						<div id="nameRequired" className="cuStatusMessage required">
							{this.state.nameRequired}
						</div>

					</div>

					<div className="cuInputContainer">

						<div className="cuLabel">Email</div>
						<div className="cuTextContainer">
							<input type="email" id="cuEmail" className="cuInput"
								value={this.state.email} 
								onChange={this.onEmailChange}/>
						</div>

						<div id="emailRequired" className="cuStatusMessage required">
							{this.state.emailRequired}
						</div>

					</div>

					<div className="cuInputContainer">

						<div className="cuLabel">Phone</div>
						<div className="cuTextContainer">
							<input type="text" id="cuPhone" className="cuInput"
								value={this.state.cuPhone} placeholder="optional phone" 
								onChange={this.onPhoneChange}/>
						</div>

						<div id="validPhoneRequired" className="cuStatusMessage required">
							{this.state.validPhoneRequired}
						</div>
					</div>

					<div className="cuInputContainer">

						<div className="cuLabel">Subject</div>
						<div className="cuTextContainer">
							<input type="text" id="cuSubject" className="cuInput"
								value={this.state.subject}
								onChange={this.onSubjectChange}/>
						</div>

						<div id="subjectRequired" className="cuStatusMessage required">
							{this.state.subjectRequired}
						</div>

					</div>

					<div className="cuMessageContainer" >

						<div className="cuLabel">Message</div>

						<div className="cuMessageTextContainer">
							<textarea id="cuMessageText" name="message"
								value={this.state.message}
								onChange={this.onMessageChange}
								onBlur={this.handleBlur}>
							</textarea>
						</div>

					</div>

					<div className="cuAttachmentsContainer">

						<div className="cuLabel">Attachments</div>

						<div className="cuAttachmentsCurrent">

							<div className="cuAttachmentList">
							{
								this.state.attachments.map( attachment =>
									<div key={attachment['name']} className="cuAttachmentItem">
										<div className="cuAttachmentName">
											{attachment['name']}
										</div>

										<div className="cuAttachmentDelete fgcomp"
											title="Delete Attachment"
											onClick={ () => 
												this.deleteAttachment(attachment['name'])}>
													&times;
										</div>

									</div>
								)

							}
							</div>

							<div className="cuNewFileInput">
								{this.state.fileInput}
							</div>

						</div>

					</div>

				</div>

				<div className="cuOperations">

					<div className="cuButtonContainer">
						<button className="bgcomp fgblack cuControl" 
							disabled={this.state.isSendDisabled}
							onClick={this.prepSend}>Send</button>
					</div>

					<div className="cuButtonContainer">
						<button className="bgcomp fgblack cuControl"
							onClick={this.closeContactUs}>Cancel</button>
					</div>

					{/*
					<div className="cuPhone">
						Phone: {this.state.phone}
					</div>
					*/}

					<div className="cuProgressMessage fgComp">
						{this.state.statusMessage}
					</div>

				</div>

			</div>
		);

	}

}
