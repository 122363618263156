import React from "react";
import "./page.css";

export class Page extends React.Component {

	constructor(props) {
		super(props)

		this.onPageChangeCallback = props.onPageChangeCallback;

		this.state = {
			page: props.page,
			pageNumber: props.pageNumber,
			seq: props.seq,
			height: "0em",
		}

	}

	localOnPageChange = (event) => {
		//console.log("Page.localOnPageChange()");
		this.onPageChangeCallback( event, this.state.seq);
	}

	render() {

		return (
			<div className="page" onClick={this.localOnPageChange}
				title="View Page">
				{this.state.pageNumber}
			</div>
		);

	}

}
