import React from "react";

import "./content.css";

export class Content extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			closeCallback: props.closeCallback,
			heading: props.contentHeading,
			verse_list: props.verseList,
			factory: props.factory,
		}


	}

	render() {

		return(
			<div className="content">
				
				<div className="contentTop">

					<div className="contentHeading">{this.state.heading}</div>
					<div className="contentClose" 
						onClick={this.state.closeCallback} title="Close">X</div>

				</div>

				<div className="contentVerses">
					{
						this.state.verse_list.map( ( verse, key ) => 
							this.state.factory( verse, key )
						)
					}
				</div>

			</div>
		);

	}

}
