import React from "react";

import "./type.css";
import "./chapterVerse.css";


export class ChapterVerse extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			verse_num: props.verse_num,
			verse: props.verse,
		}

	}

	componentDidMount() {
		this._verseText.innerHTML = this.state.verse.verse;
	}

	render() {
		let self = this;

		return(
			<div className="chapterFormatVerse">

				<span className="chapterFormatVerseNum">
					{this.state.verse.verse_num}&nbsp;
				</span>

				<span className="chapterFormatVerseText"
					ref={ element => self._verseText = element } >

					</span>
				
			</div>
		);

	}

}

export function makeChapterVerse( verse, key ) {
	return <ChapterVerse key={key} verse={verse}/>;
}

