import React from "react";

import "./home.css";
import {SharedData} from "../../sharedData/sharedData.js";
import {PrayerRequest} from "./prayerRequest.js";
//import {Config} from "../../config/config.js";
//import {MapLoader} from "./mapLoader.js";
//import {Link} from "react-router-dom";

const CONTACT_US = "contact_us";
const SHOW = "show";

export class Home extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			tdStyle: {},
			showPrayerRequest: false,
		}

		//console.log(Config.getMapSpec());

		this.loadTimeout = null;
		this.timeOut = null;

	}

	componentDidMount() {
		//console.log( "Home:componenentDidMount()" );

		let homeTab = document.querySelector( "#" + SharedData.getHomeTabId() );
		let slideMenuItem = document.querySelector( "#" + SharedData.getHomeMenuItemId() );

		if ( homeTab ) {
			//console.log( "hiding the home tab")
			homeTab.style.visibility = "hidden";
		}

		if ( slideMenuItem ) {
			//console.log( "hiding home slide menu item" );
			slideMenuItem.style.visibility = "hidden";
		}

		let searchParams = new URL(window.location).searchParams;

		if ( SHOW === searchParams.get(CONTACT_US) ||
			"1" === searchParams.get("c") ) {
			this.timeOut = setTimeout(SharedData.showContactUs, 1000);
		} else if ( "1" === searchParams.get("p") ) {
			this.timeOut = setTimeout(this.showPrayerRequest, 1000);
		}

	}

	componentWillUnmount() {
		//console.log( "Home:componenentWillUnMount()" );

		if (this.loadTimeout) {
			clearTimeout(this.loadTimeout);
		}

		let homeTab = document.querySelector( "#" + SharedData.getHomeTabId() );
		let slideMenuItem = document.querySelector( "#" + SharedData.getHomeMenuItemId() );

		if ( homeTab ) {
			//console.log( "showing the home tab")
			homeTab.style.visibility = "visible";
		}

		if ( slideMenuItem ) {
			//console.log( "showing HOME_SLIDE_MENU_ITEM " );
			slideMenuItem.style.visibility = "visible";
		}
		
		if ( this.timeOut ) {
			clearTimeout(this.timeOut);
		}

		new SharedData().setIsShown();
	}

	showPrayerRequest = (event) => {
		//console.log("showPrayerRequest()");
		this.setState({showPrayerRequest: true});
	}

	hidePrayerRequest = (event) => {
		//console.log("hidePrayerRequest()");
		this.setState({showPrayerRequest: false});
	}

	image_onLoad = (event) => {
		console.log("imageOnLoad");
		event.target.style.opacity = 1;
	}


	render() {
		let content = null;

		if ( false === this.state.showPrayerRequest ) {
			content = <div id="home">

					<div className="homeStatement fgcomp">

						<center><h1 className="homeHeader">
							Support for the Bible Student
						</h1></center>

					</div>

					<div className="homeImageContainer">
						<img className="homeImage" src="/images//Bible.png" alt="Bible.png"
							onLoad={this.image_onLoad}></img>
					</div>

					<div className="prayerRequestContainer">
						<span className="prayerRequest" onClick={this.showPrayerRequest}>
							Make a Prayer Request
						</span>
					</div>

				</div>
			} else {
				content = <PrayerRequest closeCallback={this.hidePrayerRequest}/>
			}

			return(content);
		}

}
