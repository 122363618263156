import React from "react";

import "./type.css";
import "./standardVerse.css";

export class StandardVerse extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			verse: props.verse,
		}

	}

	componentDidMount() {
		this._verseText.innerHTML = this.state.verse.verse;
	}

	render() {
		let self = this;

		return(
			<div className="standardVerse">

				<div className="standardReference">
				
					<span className="standardBook">
						{this.state.verse.book_name}&nbsp;
					</span>

					<span className="standardChapterNum">
						{this.state.verse.chapter_num}:
					</span>

					<span>{this.state.verse.verse_num};</span>

				</div>

				<div className="standardVerseText"
					ref={ element => self._verseText = element } ></div>
				
			</div>
		);

	}

}

export function makeStandardVerse( verse, key ) {
	return <StandardVerse key={key} verse={verse}/>;
}

