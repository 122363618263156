import React from "react";
import {Link} from "react-router-dom";
import "./menuCategory.css";
//import {SharedData} from "../../sharedData/sharedData.js";
import {Utils} from "../../utils/utils.js";

export class MenuCategory extends React.Component {

	constructor(props) {
		super(props);

		this.category = props.category;
		this.routes = props.routes;

		this.state = {
			//isContainerVisible: this.category ? false : true
			isContainerVisible: true
		};

		let self = this;

		this.categoryElement = null !== this.category ? 
			<h3 className="fgcomp menuCategory categoryItem"
				onClick={self.toggleShowContainer}>
				{this.category}
			</h3> :
			<div></div>

		//let label = this.routes[0].label;

		//console.log( "label: " + this.routes[0].label );
		let labelStyle = {};

		if (null != this.category) {
			labelStyle = {paddingLeft: ".5em",};
		}

		/*
		if ( "Home" === label ) {
			//console.log( "show home");
			this.container =
				<div id={SharedData.getHomeMenuItemId() } 
					className="routeContainer">
					{
						this.props.routes.map( route => {

								return (
									<div key={route.path} className="routeLinkContainer">
										<Link to={route.path} className="routeLink"
											onClick={Utils.hideAllDropDowns} >
											<h3 className="defaultRouteLinkLabel">
												&bull; {route.label}
											</h3>
										</Link>
									</div>
								)
							}
						)
					}
				</div>

		} else if ( "About Us" === label ) {
			//console.log( "show About Us");
			this.container =
				<div className="routeContainer"> {
						this.props.routes.map( route => {
								return (
									<div key={route.path} className="routeLinkContainer">
										<Link to={route.path} className="routeLink"
											onClick={Utils.hideAllDropDowns}>
											<h3 className="aboutUsItem">&bull; {route.label}</h3>
										</Link>
									</div>
								)
							}
						)
					}
				</div>
		} else {
			//console.log( "show generic");
			this.container =
				<div className="routeContainer"> {
						this.props.routes.map( route => {

								return (
									<div key={route.path} className="routeLinkContainer">
										<Link to={route.path} className="routeLink"
											onClick={Utils.hideAllDropDowns}>
											<h3 style={labelStyle} className="defaultRouteLinkLabel">
												&bull; {route.label}
											</h3>
										</Link>
									</div>
								)
							}
						)
					}
				</div>
		}
		*/

		this.container =
			<div className="routeContainer"> {
					this.props.routes.map( route => {

							return (
								<div key={route.path} className="routeLinkContainer">
									<Link to={route.path} className="routeLink"
										onClick={Utils.hideAllDropDowns}>
										<h3 style={labelStyle} className="defaultRouteLinkLabel">
											&bull; {route.label}
										</h3>
									</Link>
								</div>
							)
						}
					)
				}
			</div>

	}

	toggleShowContainer = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.setState(
			{ isContainerVisible: !this.state.isContainerVisible }
		);

	}

	render() {

		return (
			<React.Fragment>
				{this.categoryElement}
				{ this.state.isContainerVisible && this.container }
			</React.Fragment>
		);

	}

}
