import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import {BrowserRouter as Router, Route, Switch, Redirect} 
	from "react-router-dom";
import "./fonts.css";
import "./custom.css";
import "./theme.css";
import {ContactUs} from "./components/contactUs/contactUs.js";
import {Header} from "./components/header/header.js";
import {TabbedMenu} from "./components/tabbedMenu/tabbedMenu.js";
import {SlideMenu} from "./components/slideMenu/slideMenu.js";
import Navigation from "./navigation.js";
import {Config} from "./config/config.js";

const IMG = "IMG";

class App extends React.Component {

	constructor(props) {
		super(props);

		this.routes = [];

		for ( let group of Navigation.groups ) {

			for ( let newRoute of group.routes ) {
				this.routes.push( newRoute );
			}

		}

		this.contactUs = <ContactUs callback={this.hideContactUs}/>;
		this.slideMenu = <SlideMenu navigation={Navigation} routes={this.routes}/>;
		this.tabbedMenu = <TabbedMenu navigation={Navigation} routes={this.routes}/>

		this.state = {
			showContactUs: false,
		}

		 let host = window.location.host.split(':')[0];
		 this.urlPrefix = window.location.protocol + "//" + host + "/";
	}

	componentDidMount() {
	
		for ( let body of document.getElementsByTagName('body') ) {

			body.addEventListener('contextmenu',
				(event) => { 

					if ( IMG === event.target.tagName ) {
						//console.log(event.target.tagName);
						event.preventDefault(); 
					}

				} );

		}

		this.prepHit()

	}

	prepHit = () => {
		let url = this.urlPrefix + Config.getLogHitUrl();

		//console.log(url);

		let options = {
			METHOD: 'GET',
		};

		let request = new Request(url, options);

		this.logHit(request);

	}

	async logHit(request) {

    try {
			let response = await fetch(request);

			if (response.ok) {
				 await response.text();
				//let text = await response.text();
				//console.log(text);
			} else {
				let text = await response.text();
				console.error(text);
			}

		}

		catch(exc) {
			console.error(exc);
		}

	}


	showContactUs = () => {
		//console.log( "showContactUs()" );
		this.setState(
			{showContactUs: true }
		);
	}

	hideContactUs = () => {
		//console.log( "hideContactUs()" );
		this.setState(
			{showContactUs: false }
		);
	}

	render() {

		if ( !this.state.showContactUs ) {
			return(
				<Router basename="/">
					<div id="main">
						<Header callback={this.showContactUs}/>
						{this.tabbedMenu}
						{this.slideMenu}
						<div id="content">
							<Switch>
								{
									this.routes.map( route =>
										<Route path={route.path} exact={true} key={route.path} 
											render={() => route.component}/>
									)
								}
								<Redirect exact={false} to={this.routes[0].path}/>
							</Switch>
						</div>

					</div>
				</Router>
			);
		} else {

			return (
				<div>
					{this.contactUs}
				</div>
			);
		}

	}

}

export default App;
