import React from "react";
import "./hamburger.css";

export class Hamburger extends React.Component {

	render() {

		return (
			<div id="leftHamburger" className="hamburger" title="Menu">
				<div className="paddy" ></div>
				<div className="paddy"></div>
				<div className="paddy" ></div>
			</div>
		);

	}

}
