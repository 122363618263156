import React from "react";
import "./header.css";
import {Utils} from "../../utils/utils.js";
import {SharedData} from "../../sharedData/sharedData.js";


export class Header extends React.Component {

	componentDidMount() {
		SharedData.setShowContactUsCallback(this.props.callback);
	}

	render() {

		return (
			<div id="header" className="headerText bgbase">
				<div id="leftHeader">
					<img src="/images/logo.png" alt="" className="headerLogo notAllowed"
					onContextMenu={Utils.blockRightClick}></img>
				</div>
				<h2 id="centerHeader">
					Herald Light
				</h2>
				<div id="rightHeader">
					<div id="contactUsButton" className="contactText" 
						onClick={this.props.callback}>Contact</div>
				</div>
			</div>
		);

	}

}
