import React from "react";

import "./selectedWord.css";

export class SelectedWord extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			word: props.word,
			remove_callback: props.remove_callback,
		};

	}

	render() {

		return(
			<div className="listedWordRow">

				<div className="listedWord">{this.state.word}</div>
				<div className="listedWordRemove" 
					onClick={ () => this.state.remove_callback(this.state.word)}
					title="Remove From List">X</div>

			</div>
		);

	}

}

