import React from "react";
import "./articles.css";
import {Config} from "../../config/config.js";
import {Article} from "./article.js";

const NEWS = "News";
const MAIN = "Main";
const SELECTED_ARTICLE = "a";

export class Articles extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			news: null,
			category: props.category,
			current_articles: [],
			selected_article: "",
		};

		let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";

	}

	componentDidMount() {
		let url = this.urlPrefix + Config.getArticlesUrl() + 
			"?select=all&category=" + this.state.category;

		let options = {
			method: "GET",
		}

		let request = new Request( url, options);

		this.getArticles(request);

	}

	async getArticles(request) {

		try {
			let response = await fetch(request);

			if (response.ok) {
				let data = await response.json();

				let current_articles = data.current_articles.filter( article =>
					"1" === article.is_published.toString() && 
					'News' !== article.title).sort( (a,b) => a.sequence > b.sequence );

				let category_set = new Set();

				let expand_states = {};

				for ( let article of current_articles ) {
					category_set.add(article.category);
					expand_states[article.title] = false;
				}

				let news = null;

				for ( let article of current_articles ) {

					if ( MAIN === article.category && NEWS === article.title ) {
						news = article;
						break;
					}

				} // for

				let selected_article = 
					new URL(window.location).searchParams.get(SELECTED_ARTICLE);

				if (!selected_article) {

					if ( 1 === current_articles.length ) {
						selected_article = current_articles[0].title;
					} else {
						selected_article = "";
					}
				}

				let newState =
					{
						news: news,
						current_articles: current_articles,
						selected_article: selected_article,
					};

				newState = { ...newState, ...expand_states };
				this.setState(newState);

			} else {
				let errorMessage = await response.text();
				console.error(errorMessage);
			}

		}

		catch (exc) {
			console.error(exc.toString());
		}

	}

	expand_callback = (title) => {
		let expand_states = {};

		for ( let article of this.state.current_articles ) {

			if ( article.title === title ) {
				expand_states[article.title] = true;
			} else {
				expand_states[article.title] = false;
			}

		}

		this.setState(expand_states);
	}

	render() {
		let news = <div></div>

		if (this.state.news) {
			let is_selected = false;

			if ( "News" === this.state.selected_article ) {
				is_selected = true;
			}

			news = <Article article={this.state.news} is_selected={is_selected}/>
		}

		return(

			<div className="articles">

				<div className="articlesHeading">
					{this.state.category}
				</div>

				<div className="articlesContent">
					{news}
					{
						this.state.current_articles.map( article => {
								let is_selected = false;

								if ( this.state.selected_article === article.title) {
									is_selected = true;
								}

								return <Article key={article.uuid} article={article}
									is_selected={is_selected} 
									expand_state={this.state[article.title]}
									expand_callback={this.expand_callback}/> 
							}

						)
						
					}
				</div>

			</div>
		);

	}

}
