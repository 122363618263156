import React from "react";
import "./song.css";


export class Song extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			title: props.title,
			num: props.num,
			index: props.index,
			callback: props.callback,
		}
		
	}

	render() {

		return(
			<div className="song" 
				onClick={() => this.state.callback(this.state.index)}>
				<div className="songSide">{this.state.num}</div>
				<div className="songTitle">{this.state.title}</div>
				<div className="songSide"></div>
			</div>
		)

	}

}
