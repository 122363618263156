import React from "react";
import "./tabbedMenu.css";
import "./tab.css";
import {Tab} from "./tab.js";

export class TabbedMenu extends React.Component {

	constructor(props) {
		super(props);

		this.navigation = props.navigation;
	}

	render() {

		return (
				<div id="menuStripe">
				{
					this.navigation.groups.map( (group, index) =>
						<Tab key={group.routes[0].path} group={group} 
							index={index}/>
					)
				}
				</div>
		);

	}

}
