import React from "react";
import {Home} from "./components/home/home.js";
//import {AboutUs} from "./components/aboutUs/aboutUs.js";
//import {FlexGallery} from "./components/galleries/flexGallery.js";
//import {FlexGalleryImage} from "./components/galleries/flexGalleryImage.js";
import {Articles} from "./components/articles/articles.js";
//import {Reviews} from "./components/reviews/reviews.js";
//import {FlexTable} from "./components/flexTable/flexTable.js";
import {Lexicon} from "./components/lexicon/lexicon.js";
import {Search} from "./components/search/search.js";
import {SongBook} from "./components/songbook/songbook.js";

/**
	The Home page is navigated by the first Tab in the TabbedMenu 
*/

const Navigation = {
	groups: [

		{
			category: null,
			routes: [
				{ path: "/", component: <Home/>, label: "Home" },
			]
		},

/*

		{
			category: null,
			routes: [
				{ path: "/aboutus", component: <AboutUs/>, label: "About Us" },
			]
		},
*/

/*
		{
			category: "Features",
			routes: [

				{ path: "/mobileReadyFeature", component: 
					<FlexGallery config={{galleryName: "Mobile Ready"}}/>, 
						label: "Mobile Ready" },

				{ path: "/manageableFeature", component: 
					<FlexGallery config={{galleryName: "Manageable"}}/>, 
						label: "Manageable" },

				{ path: "/contactsFeature", component: 
					<FlexGallery config={{galleryName: "Contacts"}}/>, 
						label: "Contacts" },

				{ path: "/galleryFeature", component: 
					<FlexGallery config={{galleryName: "Galleries"}}/>, 
						label: "Galleries" },

				{ path: "/galleryImageFeature", component: 
					<FlexGalleryImage config={{galleryName: "Galleries Image Only"}}/>, 
						label: "Grand Canyon" },

				{ path: "/articlesFeature", component: 
					<FlexGallery config={{galleryName: "Articles and News"}}/>, 
						label: "Articles and News" },

				{ path: "/tablesFeature", component: 
					<FlexGallery config={{galleryName: "Tables"}}/>, 
						label: "Tables" },

				{ path: "/mapsFeature", component: 
					<FlexGallery config={{galleryName: "Maps"}}/>, 
						label: "Maps" },

				{ path: "/reviewsFeature", component: 
					<FlexGallery config={{galleryName: "Review Control"}}/>, 
						label: "Reviews Control" },
			]

		},
		*/


		{
			category: null,
			routes: [
				{ path: "/basics", 
				component: <Articles category="Basics"/>, 
				label: "Basics" },
			]
		},

		{
			category: null,
			routes: [
				{ path: "/collections", 
				component: <Articles category="Collections"/>, 
				label: "Collections" },
			]
		},

		{
			category: "Studies",
			routes: [
				{ path: "/studies/Figures of Speech", 
					component: <Articles category="Figures of Speech"/>, 
					label: "Figures of Speech" },
			]
		},

		{
			category: "Resources",
			routes: [
				{ path: "/resources/search",

					component: <Search heading="King James Bible Search"
						database="kjv"/>, label: "Bible Search"}, 

				{ path: "/resources/lexicon", 
					component: <Lexicon/>, label: "Lexicon" },

				{ path: "/resources/links", 
					component: <Articles category="Resources"/>, 
					label: "Study Links" },

				{ path: "/resources/songbook",
					component: <SongBook/>, label: "Song Book" },

			]
		},

		/*
		{
			category: null,
			routes: [
				{ path: "/information", component: 
				<Articles category="Information"/>, label: "Information" },
			]
		},
		*/

		/*
		{
			category: null,
			routes: [
				{ path: "/reviews", component: <Reviews/>, label: "Reviews" },
			]
		},
		*/

		/*
		{
			category: null,
			routes: [
				{ path: "/debugGallery", 
					component: <FlexGallery config={{galleryName: "Debug Gallery"}}/>,
						label: "Debug Gallery", },
			]
		},
		*/


		/*
		{
			category: null,
			routes: [
				{ path: "/flexTable", 
					component: <FlexTable config={{tableName: "Flex Grid Table"}}/>, 
					label: "Flex Grid Table" },
			]
		},
		*/
	]

};

export default Navigation;
