import React from "react";
import "./book.css";
import {Page} from "./page.js";

const COLLAPSED_CLASS = "pages";
const EXPANDED_CLASS = "pagesExpanded";

export class Book extends React.Component {

	constructor(props) {
		super(props)

		this.onPageChangeCallback = props.onPageChangeCallback;

		this.state = {
			name: props.name,
			page_list: props.page_list,
			pages_class: COLLAPSED_CLASS,
			is_expanded: false,
		}

		this.firstPage = props.firstPage || 1;
	}

	localOnPageChange = (event, seq) => {
		//console.log("book.localOnPageChange()");
		//console.log(seq);

		this.onPageChangeCallback(event, seq, this.state.name);
	}

	toggle = (event) => {
		//console.log("toggle()");
		let pages_class = COLLAPSED_CLASS;
		let is_expanded = !this.state.is_expanded;

		if ( true === is_expanded ) {
			pages_class = EXPANDED_CLASS;
		}

		this.setState(
			{
				is_expanded: is_expanded,
				pages_class: pages_class,
			}
		);

		if ( true === is_expanded) {
			let target = event.target;

			setTimeout( () => target.scrollIntoView({behavior: "smooth",}), 500);
		}

	}

	render() {

		return (
			<div className="book">

				<div className="bookName" onClick={this.toggle}
					title="See Pages">
					{this.state.name}
				</div>

				<div className={this.state.pages_class}>
					<div className="pagesTitle">Pages:</div>
					{
						this.state.page_list.map( (page, seq) =>
							<Page key={seq} seq={seq}
								pageNumber={this.firstPage++}
								onPageChangeCallback={this.localOnPageChange}/>
						)
					}
				</div>

			</div>
		);

	}

}
