import React from "react";
import {Config} from "../../config/config.js";
import "./lexicon.css";
import {Book} from "./book.js";

const LEXICON_DIR = "/lexicon/";

const DEFAULT_BACKGROUND = "white";
const SELECTED_BACKGROUND = "lightgrey";

const MIN_MOVEMENT_RANGE = 75;

const DEFAULT_IMAGE_CLASS = "lexiconImage";
const LEFT_IMAGE_CLASS = "lexiconImage lexiconImageLeft";
const RIGHT_IMAGE_CLASS = "lexiconImage lexiconImageRight";
const IMAGE_CLASS_HIDDEN = " lexiconImageHidden";

const firstPage = {
	Matthew: 1,
	Mark: 88,
	Luke: 145,
	John: 240,
	Acts: 311,
	Romans: 403, 
	'1 Corinthians': 436, 
	'2 Corinthians': 468, 
	Galatians: 490,
	Ephesians: 500, 
	Philippians: 512,
	Colossians: 520,
	'1 Thessalonians': 528,
	'2 Thessalonians': 535,
	'1 Timothy': 539,
	'2 Timothy': 548,
	Titus: 554,
	Philemon: 558,
	Hebrews: 560,
	James: 584,
	'1 Peter': 593,
	'2 Peter': 602,
	'1 John': 608,
	'2 John': 617,
	'3 John': 619,
	Jude: 620,
	Revelation: 623,
};

export class Lexicon extends React.Component {

	constructor(props) {
		super(props)

		let touch = false;

		/*
		if ( "maxTouchPoints" in navigator & navigator.maxTouchPoints > 0 ) {
			touch = true;
		}
		*/

		//console.log(touch);

		this.state = {
			book_list: [],
			book_dict: {},
			current_book: "",
			current_seq: 0,
			image_url: "",
			show_swipe_msg: false,
			first_swipe: false,
			image_class: DEFAULT_IMAGE_CLASS,
			touch: touch,
		}

		this.lexiconUrl = Config.getLexiconUrl();

	}

	componentDidMount() {
		//console.log(this.lexiconUrl);

		let options = {
			method: "GET",
		};

		this._image.addEventListener("touchstart", this.onTouchStart);
		this._image.addEventListener("touchmove", this.onTouchMove);
		this._image.addEventListener("touchend", this.onTouchEnd);

		let request = new Request(this.lexiconUrl, options);

		this.retrieveBookList(request);
	}

	onPageChangeCallback = (event, seq, book) => {
		//console.log("lexicon.onPageChangeCallback()");
		//console.log("seq: " + seq);
		//console.log("book: " + book);

		try {
			let image_file = this.state.book_dict[book][seq];
			//console.log(image_file);
			let url = LEXICON_DIR + "/" + book + "/" + image_file;
				image_file.replace(/ /g, "%20");
			url = url.replace( / /g, "%20");
			//console.log(url);

			this.setState( 
				{
					image_url: url,
					current_book: book,
					current_seq: seq,
				} 
			);

			let target = event.target;

			const onSelect = () => {
				this._image.scrollIntoView( {behavior: "smooth",});
				this.deselect();
				target.style.backgroundColor = SELECTED_BACKGROUND;
			};

			setTimeout( onSelect, 500);

			if ( false === this.state.touch ) {

				if ( false === this.state.first_swipe ) {

					setTimeout( () => this.setState( 
						{
							show_swipe_msg: true,
							first_swipe: true,
						}
					), 0 );

					setTimeout( () => this.setState(
						{
							show_swipe_msg: false,
						}
					), 3000);

				}

			}

		}

		catch ( exc) {
			console.error(exc);
		}

	}

	deselect = () => {
		let page_list = document.querySelectorAll(".page");
		
		for ( let i = 0; i < page_list.length; ++i ) {
			page_list[i].style.backgroundColor = DEFAULT_BACKGROUND;
		}

	}

	async retrieveBookList(request) {

		try {
			let response = await  fetch(request);

			if ( response.ok) {
				let data = await response.json();
				//console.log("front " + data.front);
				//console.log(data.book_list);
				let book_dict = {};

				for ( let i = 0; i < data.book_list.length; ++i ) {
					let book = data.book_list[i];
					//console.log(book);
					book_dict[book.name] = book.page_list;
				}

				this.setState(
					{ 
						book_list: data.book_list,
						book_dict: book_dict,
						image_url: LEXICON_DIR + data.front,
					}
				);

				//console.log(data);
			} else {
				console.error(response.text());
			}

		}

		catch(exc) {
			console.error(exc.toString());
		}

	}

	onTouchStart = (event) => {
		let touch = event.targetTouches.item(0);
		this.startX = touch.clientX;
	}

	onTouchMove = (event) => {
		let touch = event.targetTouches.item(0);
		this.endX = touch.clientX;
	}

	onTouchEnd = (event) => {
		let movement = parseInt(this.endX) - parseInt(this.startX);
		this.handleSwipe(movement);
	}

	onMouseDown = (event) => {
		//console.log("onMouseDown()");
		event.preventDefault();
		//console.log(event);
		//console.log(event.clientX);
		this.startX = parseInt(event.clientX);
	}

	onMouseUp = (event) => {
		//console.log("onMouseUp()");
		let endX = parseInt(event.clientX);
		let movement = endX - this.startX;

		this.handleSwipe(movement);
	}


	handleSwipe = (movement) => {
		let movementRange = Math.abs(movement);

		if ( movementRange >= MIN_MOVEMENT_RANGE && 
			this.state.current_book.length > 0 ) {
			let pages = this.state.book_dict[this.state.current_book];
			let pageLength = pages.length;

			let newSeq = null;
			let imageClass = RIGHT_IMAGE_CLASS + IMAGE_CLASS_HIDDEN;
			let imageClass2 = RIGHT_IMAGE_CLASS;

			if ( movement < 0 ) {
				imageClass = RIGHT_IMAGE_CLASS + IMAGE_CLASS_HIDDEN;
				imageClass2 = RIGHT_IMAGE_CLASS;

				if ( this.state.current_seq < pageLength - 1 ) {
					newSeq = this.state.current_seq + 1;
				}

			} else if ( movement > 0 ) {
				imageClass = LEFT_IMAGE_CLASS + IMAGE_CLASS_HIDDEN;
				imageClass2 = LEFT_IMAGE_CLASS;

				if ( this.state.current_seq > 0 ) {
					newSeq = this.state.current_seq - 1;
				}

			}

			if ( null !== newSeq ) {
				let image_file = this.state.book_dict[this.state.current_book][newSeq];
				let url = LEXICON_DIR + "/" + this.state.current_book + "/" + image_file;
					image_file.replace(/ /g, "%20");
				url = url.replace( / /g, "%20");

				this.setState( 
					{
						image_url: url,
						current_seq: newSeq,
						image_class: imageClass,
					} 
				);

				setTimeout( this.deselect, 0);
				this._image.scrollIntoView({ behavior: "smooth",});

				setTimeout( () => this.setState({image_class: imageClass2}), 200);
				setTimeout( () => 
					this.setState({image_class: DEFAULT_IMAGE_CLASS}), 300);

			}

		}

	}

	render() {
		let self = this;

		return(
			<div className="lexicon">

				<div className="lexiconTitle">
					The Englishman's Greek New Testament
				</div>

				<div className="lexiconMain">

					<div className="lexiconImageBox">
						{this.state.show_swipe_msg &&
							<div className="swipeMsg" >Swipe to Turn Pages Left or Right</div>
						}
						<img id="lexiconImage" className={this.state.image_class} 
							ref={element=> self._image = element}
							src={this.state.image_url} alt=""
							onMouseDown={this.onMouseDown}
							onMouseUp={this.onMouseUp} 
							></img>
					</div>

					<div className="lexiconMenuBox"> 
						{
							this.state.book_list.map( (book, seq) =>
								<Book key={seq}name={book.name} page_list={book.page_list}
									seq={seq} firstPage={firstPage[book.name]}
									onPageChangeCallback={this.onPageChangeCallback}/>
							)
						}
					</div>

				</div>

			</div>
		);

	}

}
